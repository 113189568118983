import { FC } from "react";

const NotFound: FC = (): JSX.Element => {
    return (
        <div>
            <h1>Not found</h1>
        </div>
    )
};

export default NotFound;